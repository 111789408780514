import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import IntroVideo from '../views/IntroVideo.vue'
import MainNavigation from '../views/MainNavigation.vue'

const routes = [
  {
    path: '/:purl?',
    name:'Home',
    component: Home
  },  
  {
    path: '/:purl?/intro',
    name:'Intro Video',
    component: IntroVideo
  },  
  {
    path: '/:purl?/navigation',
    name: 'Main Navigation',
    component: MainNavigation
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router