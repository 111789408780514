<template>    
    <div class="site" :class="[$store.state.devMode ? 'dev-mode' : '', this.$store.state.showBuildMode ? 'show-build-mode' : '', showSitemap ? 'show-sitemap' : '', this.$route.name === 'Home' || this.$route.name === 'Intro Video' ? 'at-home' : '']">
      <div class='site-holder'>    
        <Navigation @toggleSitemap="showSitemap = !showSitemap"/>    
        <Home class="content" v-if="docraptor" />
        <router-view v-else class='content' v-slot="{ Component, route }">          
            <component :is="Component" :key="route.path" />
        </router-view>

      </div>
      <DraggableSitemap v-if="$store.state.rep.name === 'Richard Pitul' && $store.state.devMode" @hideSitemap="showSitemap = false;"/>      
      <Sitemap v-else @hideSitemap="showSitemap = false;"/>      
      <div class="build-mode" v-if="$store.state.devMode" :class="{'show-build-mode':this.$store.state.showBuildMode}">
        <button class='save' @click="savePage">SAVE</button>
        <div class="template-selector">
          <button :class="[
            $store.state.templates[key] === $route.meta.template ? 'active' : '', 
          key === activeOverride ? 'override-active' : ''
          ]" @click="activeOverride = key; $store.state.overrideTemplate = key" v-for="(template, key) in $store.state.templates"><img :src="`${$store.state.baseUrl}0/admin/images/templates/${key}.jpg`" alt=""><span>{{template.replace('.vue', '')}}</span></button>
        </div>
      </div>
    </div>        
</template>

<script>
/*

*/
import axios from 'axios';
import Sitemap from "@/components/Sitemap";
import DraggableSitemap from "@/components/DraggableSitemap";
import Navigation from "@/components/Navigation";
import Home from "@/views/Home";
export default {
    name:'Speedway',       
    components : {
      DraggableSitemap,
      Sitemap,
      Navigation,
      Home
    },    
    data() {
        return {
          showSitemap:false,
          docraptor:false,
          activeOverride:undefined,
        }
    },
    watch :{
        '$route.params' (to) {
          //console.log('route', this.$route)
          this.activeOverride = undefined;
          if(to.purl && to.purl !== this.$store.state.purl && to.purl !== `preview.${this.$store.state.purl}`){
            this.getPurl();
          }
        }
    },
    methods : {  
      onSavePage(response) {
        console.log(response)
      },
      savePage() {
        let confirm = window.confirm('Are you sure you would like to save this page?');
        if(confirm) {
          const copy = this.$store.state.overrideCopy !== undefined ? this.$store.state.overrideCopy : this.$route.meta.copy;
          const template = this.activeOverride ? this.activeOverride: this.$route.meta.template;
          const params = new URLSearchParams();
          params.append("pid", this.$store.state.presentationId);
          params.append("cid", this.$route.meta.id);
          params.append("title", this.$route.meta.title);          
          params.append("template", template);
          params.append("copy", copy);       
          let matched = this.$route.matched[this.$route.matched.length - 1];
          //the next two lines let the copy and template persist after changes without updating the database
          matched.props.default.page.copy = copy;
          matched.meta.template = template;
          //experimental code do not uncomment
          //axios.post('https://speedwaypartner.com/0/admin/submitPage.php', params).then(this.onSavePage);          
        }
      },
      getPurl() {
        let pathParts = this.$route.fullPath.split('/');      
        pathParts.shift();
        const possiblePurl = pathParts.shift();
        let purl = this.$route.params.purl ?? possiblePurl ?? '';
        console.log('get purl', purl)    
        for (const key in this.$route.query) {         
          if(key === 'cid') {
            this.$store.commit('setPreviewPage', this.$route.query.cid);  
          } else if (key === 'print')         {
            purl = this.$route.query.print;
            console.log('print purl', purl)  
            this.docraptor = true;
            this.$store.commit('setPreview', true);   
          }
        }                
        if(purl?.indexOf('preview.') == 0) {
            const previewPurl = purl.split('preview.');
            purl = previewPurl.pop();
            this.$store.commit('setPreview', true);        
            console.log('preview')
        }        

        axios.post(this.$store.state.baseUrl + "0/getInfoNew.php", {purl:purl, isTesting:this.$store.state.preview})
        .then(this.getSite)
        .catch(function (error) {
            console.log(error);
        }); 
      },    
      getSite(response) {        
        if(response.data) {
          console.log(response.data)
          this.$store.commit('setIntro', response.data.arrMyInfo.introUrl ?? '');  
          this.$store.commit('setRep', response.data.arrMyInfo);        
          this.$store.commit('setSessionDBid', response.data.sessionId);        
          this.$store.commit('setPresentationId', response.data.arrMyInfo.id);        
          this.$store.commit('setClient', response.data.arrMyInfo);        
          this.$store.commit('setPurl', response.data.arrMyInfo.purl);          
          this.$store.commit('setSitemap', {arrTopNav:response.data.arrTopNav, signageList:response.data.arrSignages, sectionInfo: response.data.arrSectionInfo, contentInfo:response.data.arrContentInfo});
          this.$store.commit('setTrackLogo', {arrTopNav:response.data.arrTopNav, sectionInfo: response.data.arrSectionInfo});
          //window.printReady = 1;
        }
      },
      doCommand(e) {
        //alert(e.keyCode)
        //49 is 1 : 57 is 9
        //37 is left arrow
        //39 is right arrow
        //38 is up
        //40 is down
        //console.log('currentPage', this.$store.state.currentPage)
        let newPath = undefined;
        if(this.$store.state.rep.name === 'Richard Pitul' && this.$store.state.devMode && this.$store.state.preview) {
          if(e.keyCode === 66) {  //b key
            this.$store.state.showBuildMode = !this.$store.state.showBuildMode;
          }
          return;
        }

        if(e.keyCode === 83) {  //s key opens sitemap
          this.showSitemap = !this.showSitemap;
        } else  if(e.keyCode === 39 && this.$store.state.currentPage?.next) {
          newPath = this.$store.state.currentPage?.next;
        } else if(e.keyCode === 37 && this.$store.state.currentPage?.previous) {
          newPath = this.$store.state.currentPage?.previous;
        }
        if(newPath) {
          this.$router.push({name:newPath, params:this.$route.params});
        }
      }
    },
    created() {
      window.removeEventListener('keydown', this.doCommand);
      window.addEventListener('keydown', this.doCommand);            
      console.log('create')
      setTimeout(() => {
        this.getPurl();    
      }, 100);   
      
    }
}

</script>
<style lang="scss">
  @font-face {
      font-family: 'Gotham Book';
      src: url('~@/assets/fonts/Gotham-Book.woff2') format('woff2'),
          url('~@/assets/fonts/Gotham-Book.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('~@/assets/fonts/Gotham-Bold.woff2') format('woff2'),
          url('~@/assets/fonts/Gotham-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Helvetica Neue LT Std';
      src: url('~@/assets/fonts/HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
          url('~@/assets/fonts/HelveticaNeueLTStd-BdCn.woff') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Helvetica Neue LT Std';
      src: url('~@/assets/fonts/HelveticaNeueLTStd-BlkCn.woff2') format('woff2'),
          url('~@/assets/fonts/HelveticaNeueLTStd-BlkCn.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('~@/assets/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
        url('~@/assets/fonts/HelveticaNeueLTStd-Th.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  .text-content {
    h1 {
      font-family: 'Helvetica Neue LT Std';
      font-weight:900;
    }
  }
  .copy {
    font-family: 'Gotham Book';
    a {
      color:inherit;
    }
  }
</style>
<style lang="scss" scoped>  
  #app .site div.build-mode {
    position:fixed;
    top:0;
    left:0; 
    transform:translateX(0);
    transition: transform 0.2s ease-in-out;    
    .save {
      width:100%;
      height:30px;
      background:#BD2531;
      color:#fff;
      font-family: 'Helvetica Neue LT Std';
      font-weight:900;
      border:none;
      font-size:1.25rem;
    }
    &:not(.show-build-mode) {
      transform:translateX(-300px)
    }
  }
  .template-selector {   
    height:calc(100vh - 36px);
    overflow-x:hidden;
    overflow-y:auto;
    width:300px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #202020;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0066CC;
    }
    button {
      opacity: 0.7;
      &.active, &.override-active {
      border:4px solid #0066CC;      
      opacity: 1;
      }
      &.override-active {
        border:4px solid #BD2531;      
      }
    }
    img {
      width:100%;
    }
  }
  #app div.site {
    position: relative;
    overflow: hidden;
    > div {
      transition: 0.4s transform ease-in-out;
      transform: translateX(0);
    }    
    &.dev-mode .site-holder {
      transition: 0.4s all ease-in-out;
    }
    &.show-build-mode {
      .site-holder {
         transform: translateX(300px);
         width:calc(100% - 300px);
      }
    }
    &.show-sitemap {
      .sitemap {
        transform: translateX(0);
        &:before {
          transition: 0.4s 0.3s opacity ease-in-out;
          opacity: 1;
          pointer-events: all;
        }
      }    
      > div {
        transform: translateX(580px);
      }
    }    
    .sitemap {
      transform: translateX(-580px);
      pointer-events: none;
    }
    .content {
      height:100%;
      width:100%;      
      overflow: auto;
    }
  }
</style>
<style lang="scss">
  .fade-enter-active {
    transition: all 0.8s ease-out;
  }
  .fade-leave-active {
    transition: all 0.3s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .slide-enter-active {
      transition: all 0.3s 0.3s ease-out;
  }
  .slide-leave-active {
      transition: all 0.3s;
  }
  .slide-enter-from,
  .slide-leave-to {
      opacity: 0;
      transform: translateX(-25px);
  }
  

  h1 {
    margin:0;
  }
  body {
    margin: 0;
    background:linear-gradient(270deg, #161616 0%, #070707 100%);
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #202020;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0066CC;
    }
  }
  @page {
    margin:0;
    size: Letter portrait;  
  }
  @media print {
    .nav {
      display:none;
    }
  }
  

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    .site {
      height: 100vh;
      display: grid;  
      grid-template-rows: 1fr 5px;      
      text-align: left;
      &:not(.at-home) .site-holder {
        width: 100%;
        overflow: hidden;
        display: grid;  
        grid-template-rows: 70px 1fr;      
      }      
      &:after {
        content:"";
        display:block;
        width: 100%;
        background: linear-gradient(270deg, #9F9F9F 0%, #FFFFFF 100%);
        position: relative;
      } 
    }
  }
  .copy {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #202020;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0066CC;
    }
  }
  @media print {
      #app {
          grid-template-rows: 1fr;
      }
  }  
</style>