<template>
  <div class="home">    
    <transition name="slide" appear>
    <div v-show="$store.state.purl !== ''">
      <p class="welcome">Welcome, {{firstName}}</p>
      <div class="logos">
        <img :src="require(`@/assets/logos/logo.svg`)" alt="" class='logo'>
        <transition name="fade" appear>
          <div v-show="$store.state.track !== ''" >
            <img v-if="$store.state.track !== ''" :src="require(`@/assets/logos/${$store.state.track}.svg`)" alt="">
          </div>
        </transition>
        <transition name="fade" appear>
          <div class='client-logo' v-show="$store.state.client.logo !== '' && $store.state.client.logo !== `${$store.state.baseUrl}logos/`" >
            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                <path d="m14.718.075.707.707L8.707 7.5l6.718 6.718-.707.707L8 8.207l-6.718 6.718-.707-.707L7.293 7.5.575.782l.707-.707L8 6.793 14.718.075Z" fill-rule="evenodd"/>
            </svg>
            <img v-if="$store.state.client.logo !== `${$store.state.baseUrl}logos/`" :src="$store.state.client.logo" alt="">
          </div>
        </transition>
      </div>
      <router-link :to="{name:'Main Navigation', params:$route.params}"><span>Begin Presentation</span></router-link>
    </div>
    </transition>                    
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    console.log('home mounted')
    this.$store.state.currentPage = {
      'next': 'Main Navigation'
    }    
  },
  computed : {
    test() {
      let t = this.$router.resolve('Main Navigation');
      
      console.log('tt', t);
      return t;
    },
    firstName() {
      return this.$store.state.client.firstName === '' ? '' : `${this.$store.state.client.firstName}.`;
    }    
  }
}
</script>
<style lang="scss" scoped>
  .logos .client-logo {
   display: flex; 
   align-items: center;
   img {
     height: 125px;
     max-width: 200px;
     object-fit: contain;
     object-position: left;
   }
   svg {
      fill:#F5F5F5;
      vertical-align: middle;
      margin:0 11px;
    }
  }  
  a {
    font-family: 'Helvetica Neue LT Std';
    font-weight: bold;
    border-radius: 10px;
    background: linear-gradient(270deg, #DADADA 0%, #FFFFFF 100%);    
    color: #181818;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    padding:16px 30px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    span {
      position: relative;
    }
    &:before {      
      content:"";      
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      box-sizing: border-box;
      border:0px solid #F5F5F5;    
      border-radius: 10px;
    }
    @media (hover: hover) {
      &:before {
        transition: 0.2s all;
      }
      &:hover {
        &:before {
          border:5px solid #F5F5F5;
        }
      }
    }
  }
  .home {
    display: grid;
    place-items: center;
    text-align: center;    
    position: relative;
    &:before, &:after  {
      content:"";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    &:before {
      background:url('~@/assets/images/bg_intro.jpg');
      background-size: cover;
      opacity: 0.2;
    }
    &:after {      
      background: linear-gradient(270deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.7) 100%)
    }
    > div {
      position: relative;
      z-index: 1;
    }
  }
  .welcome {
    font-family: 'Helvetica Neue LT Std';
    font-weight: 900;
    color: #EFEFEF;
    text-transform: uppercase;
    font-size: 2.125rem;
    letter-spacing: 0;
    line-height: 1;
  }
  .logos {
    border-top:1px solid #F5F5F5;
    border-bottom:1px solid #F5F5F5;
    padding:32px;
    margin:50px auto;
    width:calc(100vw - 100px);
    max-width: 600px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:after, &:before {
      //content: "";
      position: absolute;      
      left:50%;
      width:50px;
      height:1px;
      background:#0066CC;
      transform:translateX(-50%);
      animation-name: example;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    &:after {
      bottom:-1px;
      animation-delay:0.8s;
    }
    &:before {
      top:-1px;
    }
    @keyframes example {
      0% {width: 0%;}
      90% {opacity:1;}
      100% {width:100%; opacity:0;}
    }
    .logo {
      margin-right:12px;
    }
    img {
        height:55px;
    }
  }
  .divider {
    width:1px;
    height:45px;
    margin:0 22px;
    display:inline-block;
    background-color: #F5F5F5;
  }
</style>
