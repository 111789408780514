<template>
  <div class="sitemap">
    <div>
        <div class="level1" :class="[showLevel1 ? 'active-on-mobile' : '']">
            <div class="track" :class="{'no-brightness':$store.state.track === 'nws'}"><img v-if="$store.state.track !== ''" :src="require(`@/assets/logos/${$store.state.track}.svg`)" alt=""></div>
            <div class="items">
                <button :class="item.name === activeName ? 'active': ''" @click="setLevel2ByName(item.name); showLevel1 = false;" v-for="(item, index) in $store.state.siteMap" :key="index" :to="{name:item.name, params:$route.params}">
                    <div class="index">{{(index + 1).toString().padStart(2, '0')}}</div>
                    <div class="title">{{item.title}}</div>  
                    <img src="@/assets/icons/icon_nav-triangle.svg" alt="">                          
                </button> 
            </div>
        </div>
        <div class="level2" :class="[activeName !== '' && !showLevel1 ? 'active-on-mobile' : '']">
            <div v-for="section in level2" :key="section.name">
                <div class="section-title">
                    <button @click="showLevel1 = true">
                        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 14 7-7-7-7v4.083H0v5.834h7z" fill="#4D4D4D" fill-rule="nonzero"/>
                        </svg>
                    </button>
                    <div>{{section.title}}</div>   
                </div>
                <div class="items">                                    
                    <router-link @click="$emit('hideSitemap')" v-for="page in filteredPages(section)" :key="page.name" :to="{name:page.name, params:$route.params}">
                        <div class="title">{{page.title}}</div>  
                    </router-link>                          
                </div> 
            </div>
        </div>
        <button class='close' @click="setLevel2ByName(name); $emit('hideSitemap'); showLevel1 = false;">
            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                <path d="m14.718.075.707.707L8.707 7.5l6.718 6.718-.707.707L8 8.207l-6.718 6.718-.707-.707L7.293 7.5.575.782l.707-.707L8 6.793 14.718.075Z" fill-rule="evenodd"/>
            </svg>
        </button>
    </div>
  </div>
</template>

<script>
import { watch } from '@vue/runtime-core';
export default {
    name:'Sitemap', 
    data() {
        return {
            level2:[],
            name:"",                
            activeName:"",
            showLevel1:false
        }
    },    
    methods : {
        filteredPages(section){            
            if(section?.pages) {
                return section.pages.filter((page) => {
                    return page.template !== '1'
                })
            }
        },
        setLevel2ByName(to) {
            let notSet = true;
            for (let index = 0; index < this.$store.state.siteMap.length; index++) {
                const element = this.$store.state.siteMap[index];
                if(to === element.name) {
                    notSet = false;
                    this.level2 = element.sections;
                }
            }  
            if(notSet) {
                this.level2 = this.$store.state.siteMap[0]?.sections;
            }  
            this.activeName = to;  
        }
    },
    watch :{
        '$route.name' (to) {
            let notSet = true;
            if(this.$route.matched.length > 1) {
                to = this.$route.matched[0].name;
            }
            this.setLevel2ByName(to);
            this.name = to;  
        }
    }     
}
</script>

<style lang="scss">
.show-sitemap button.close {
    opacity: 1 !important;
    pointer-events: all;
}
</style>
<style lang="scss" scoped>
    .close {
        position: absolute;
        top:0;
        right:0;
        
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.2s 0.2s ease-in-out;
        border:none;
        background:none;
        padding:23px;
        fill:#070707;
        cursor:pointer;

        @media (min-width:640px) {
            transform:translateX(100%);            
            svg {
                fill:#F5F5F5;
            }
        }
        svg {
            width:20px;
        }
    }
    .index {
        font-family: 'Helvetica Neue LT Std';
        font-weight: 100;
    }
    .level2 {
        padding:30px;
        margin-top:50px;
        pointer-events: all;
        overflow: auto;
        font-family: 'Helvetica Neue LT Std';
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: #202020;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #0066CC;
        }
        @media (max-width:639px) {        
            width:100vw;            
            background:#070707;
            box-sizing: border-box;            
            &.active-on-mobile {
                margin-top:93px;
                position: absolute;
                left:0;
                top:0;
                height:calc(100vh - 93px);
                &:before {
                    content:"";
                    
                    position: absolute;
                    left:0;
                    top:0;
                    bottom:0;
                    width:100%;
                    
                }
                > div {
                    position: relative;                    
                }
            }
        }
        .section-title {
            text-transform: uppercase;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
            margin-bottom:17px;
            gap:8px;
            button {
                width:50px;
                height:50px;
                border:none;
                border-radius: 5px;
                background-color: #DADADA;
                svg {
                    transform:scaleX(-1);
                }
            }
            @media (max-width:639px) {   
                display: flex;
                align-items: center;
            }
            @media (min-width:640px) {   
                button {
                    display: none;
                }
            }
        }        
        .items {
            margin-bottom: 34px;
            display: flex;
            flex-direction: column;
            gap:1px;            
        }
        a {
            border-radius: 5px;
            background-color: rgba(#FFFFFF, 0.1);
            padding:10px 20px;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
            text-decoration: none;
            font-weight: bold;
            &.router-link-active  {
                background-color: #0066CC;
            }
            @media (hover: hover) {
                transition: background 0.2s ease-in-out;
                &:hover {
                    background-color: #0066CC;
                }
            } 
        }
    }
    .level1 {   
        background-color: rgba(#FFFFFF, 0.75);
        height: inherit;        
        pointer-events: all;    
        font-family: 'Helvetica Neue LT Std';    
        width:100vw;
        @media (min-width:640px) {
            width: 290px;
        }
        .track {
            text-align: center;
             background-color: #F5F5F5;
             &:not(.no-brightness) {
                img {
                filter: brightness(0);
                }
             }
             img {
                margin:22px 0;
                height:44px;
             }
        }
        .items {
            margin:10px;
            display: flex;
            flex-direction: column;
            gap:1px;
            overflow: auto;    
            max-height: calc(100vh - 120px);
            overflow-x: hidden;
            padding-right:8px;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background-color: #202020;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #0066CC;
            }
        }
        button {
            text-decoration: none;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            gap:10px;
            padding:20px 16px;
            border-radius: 5px;
            border:none;
            text-align: left;
            background: rgba(#070707, 0.4);         
            cursor: pointer;   
            .title {
                font-weight: bold;
                text-transform: uppercase;
                flex-grow: 1;
            }
            &.active  {
                background-color: #0066CC;
            }
            @media (hover: hover) {
                transition: background 0.2s ease-in-out;
                &:hover {
                    background-color: #0066CC;
                }
            } 
            
        }
    }
    .sitemap {  
        position: fixed;
        left:0;
        top:0;  
        width:100%;
        height:100%;
        > div {            
            height:100%;            
            position: relative;
            display: grid;
            width:100%;
            @media (min-width:640px) {
                width:580px;
            }
            @media (min-width:640px) {
                grid-template-columns: 290px 290px;    
            }
            &:before {
                position: absolute;
                left:0;
                top:0;    
                width:100%;
                height:100%;    
                content: "";
                opacity: 0.2;
                background-image:url('~@/assets/images/bg_nav.jpg');
                background-size:cover;
            }
        }        
        &:before {
            background-color: rgba(#070707, 0.8);            
            position: absolute;
            left:0;
            top:0;    
            width:100%;
            height:100%;    
            content: "";
            opacity: 0;
            transition: 0.2s 0s opacity ease-in-out;            
        }                
    }
</style>