<template>
  <div class="navigation">    
     <router-link v-if="$store.state.intro !== ''" :to="{name:'Intro Video', params:$route.params}" :style="{backgroundImage:`url(${require('@/assets/images/bg_placeholder.jpg')})`}">
        <div class='info'>
          <div class="index">01</div>
          <div class="title">Sizzle Reel</div>          
        </div>
        <Video v-if="$store.state.intro"                  
            class='video'
            :video='$store.state.intro'
        />  
      </router-link>      
      <router-link           
          v-for="(item, index) in filteredSitemap"          
          :data-id="item.id"
          :key="index" 
          :to="{name:getPathName(item), params:$route.params}" 
          :style="{backgroundImage:`url(${item.sections[0].pages[0].background})`}
        ">
        <div class='info'>
          <div class="index">{{(index + ($store.state.intro === '' ? 1 : 2)).toString().padStart(2, '0')}}</div>
          <div class="title">{{item.title}}</div>          
        </div>
         <Video v-if="item.video"      
            class='video'
            :video='item.videoUrl'
        />  
      </router-link> 
  </div>
</template>

<script>
//<router-link v-for="(item, index) in $store.state.siteMap" :key="index" :to="{name:item.name, params:$route.params}" :style="{backgroundImage:`url(${require(`@/assets/images/${item.sections[0].pages[0].background}`)})`}">
import Video from '@/components/Video';
export default {
  name: 'Main Navigation',
  components: {
      Video
  },
  mounted() {
    this.$store.state.currentPage = {
      'next': this.$store.state.siteMap[0]?.name,
      'previous': 'Home'
    }    
  },
  computed : {
    filteredSitemap() {
      return this.$store.state.siteMap.filter((item) => {return !this.$store.state.notOnNavigationPage.includes(item.id)})
    }
  },
  methods : {
    getPathName(item) {
      return item.sections.length === 1 ? item.sections[0].pages[0].name : item.name;
    }
  }
}
</script>
<style lang="scss" scoped>
.navigation {       
    overflow: auto;
    max-height: calc(100vh - 75px);
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #202020;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0066CC;
    }
    a {
        background-size: cover;
        background-position: center;
        flex-grow:1;
        width:100%;
        color: #F5F5F5;
        text-decoration: none;
        text-transform: uppercase;                
        box-sizing: border-box;
        padding:55px;
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow:hidden;
        .video {
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          opacity: 0;                              
        }
        @media (hover: hover) {
          .video {
            transition: opacity 0.2s ease-in-out;
          }
          &:hover {
            .video {
              opacity: 1;
            }
          }
        }
        .info {
          position: relative;
          z-index: 2;
        }
        &:before {
          box-sizing: border-box;
          content: "";
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(7,7,7,0.7) 100%);
          pointer-events: none;
          border:0px solid #F5F5F5;
          transition: 0.2s border;
          z-index: 1;
        }
        > div {
          position: relative;
          width: 100%;
          border-bottom:1px solid #0066CC;
          padding-bottom:15px;
          user-select: none;
          .index {
            font-family: 'Helvetica Neue LT Std';
            font-weight: 100;
            font-size: 3.125rem;
          }
          .title {
            font-family: 'Helvetica Neue LT Std';
            font-weight: 900;
            font-size: 2.125rem;
          }
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              border:10px solid #F5F5F5;
            }
          }
        }
    }
    @media (min-width:700px) {
      display:flex;
      flex-wrap: wrap; 
      a {
        width:50%;
      }
    } 
}
</style>
