<template>
  <div v-if="$route.name !== 'Home' && $route.name !== 'Intro Video'" class="nav">
          <div>
              <div class="info">
                <router-link :to="{name:'Main Navigation', params:$route.params}">
                    <img :src="require(`@/assets/logos/logo.svg`)" alt="" class='logo'>                    
                    <img v-if="$store.state.track !== ''" :src="require(`@/assets/logos/${$store.state.track}.svg`)" alt="">
                </router-link>
                <router-link v-if="currentSection !== ''" :to="{name:currentMain, params:$route.params}" class='parent-menu'>
                    {{currentSection}}
                </router-link>
              </div>
              <div class="ui">
                <router-link @click="clickMeetTheTeam" v-if="$store.state.showMeetTheTeam !== ''" :to="{name:toggleLinkMeetTheTeam, params:$route.params}">
                    {{meetTheTeamText}}
                </router-link>
                <button @click="$emit('toggleSitemap')">          
                    <svg width="20" height="11" viewBox="0 0 20 11" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 10v1H0v-1h20Zm0-5v1H0V5h20Zm0-5v1H0V0h20Z" fill-rule="evenodd"/>
                    </svg>
                </button>
              </div>
          </div>
        </div>
</template>

<script>
import { watch } from '@vue/runtime-core';
export default {
    name:"Navigation",
     data() {
        return {
            name:"",
            toggleLinkMeetTheTeam:"",
            meetTheTeamText:'Meet the Team',
            notInitialized:true,
        }
    }, 
    watch :{
        '$route.name' (to) {            
            this.name = to;            
            if(this.notInitialized)  {
                this.notInitialized = false;
                if(this.name === this.toggleLinkMeetTheTeam) {
                    this.meetTheTeamText = 'Home';
                    this.toggleLinkMeetTheTeam = 'Home';
                }
            }
            if(to !== this.$store.state.showMeetTheTeam) {
                this.meetTheTeamText = 'Meet the Team';
                this.toggleLinkMeetTheTeam = this.$store.state.showMeetTheTeam;
            }
        },
        '$store.state.showMeetTheTeam' (to) {
            this.toggleLinkMeetTheTeam = to;            
        }
    },     
    methods : {
        clickMeetTheTeam() {
            this.toggleLinkMeetTheTeam = this.toggleLinkMeetTheTeam === this.$store.state.showMeetTheTeam ? this.name : this.$store.state.showMeetTheTeam;
            this.meetTheTeamText = this.toggleLinkMeetTheTeam === this.$store.state.showMeetTheTeam ? 'Meet the Team' : 'Back';
        }
    },
    computed : {                   
        currentSection() {
            let sectionName = "";
            for (let index = 0; index < this.$store.state.siteMap.length; index++) {
                const element = this.$store.state.siteMap[index];
                const sections = element.sections;
                for (let sectionId = 0; sectionId < sections.length; sectionId++) {
                    const pages = sections[sectionId].pages;
                    for (let pageId = 0; pageId < pages.length; pageId++) {
                        const page = pages[pageId];
                        if(page.name === this.name) {
                            sectionName = sections[sectionId].name;
                            break;
                        }   
                    }                    
                }
            }     
            return sectionName;   
        },
        currentMain() {
            let parentName = "";
            for (let index = 0; index < this.$store.state.siteMap.length; index++) {
                const element = this.$store.state.siteMap[index];
                const sections = element.sections;
                for (let sectionId = 0; sectionId < sections.length; sectionId++) {
                    const pages = sections[sectionId].pages;
                    for (let pageId = 0; pageId < pages.length; pageId++) {
                        const page = pages[pageId];
                        if(page.name === this.name) {
                            parentName = element.name;
                            break;
                        }   
                    }                    
                }
            }     
            return parentName;   
        }
    },    
}
</script>

<style lang="scss" scoped>
    @media (max-width:636px) {
        .parent-menu {
            display:none;
        }
    }
    .parent-menu {     
        font-family: 'Helvetica Neue LT Std';
        font-weight: bold;
        color: #F5F5F5;    
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1;
        text-transform: uppercase;
        text-decoration: none;
    }
    .nav {
        transition: 0.4s transform ease-in-out;
        background: linear-gradient(270deg, #161616 0%, #070707 100%);
        display: grid;
        place-items: center;
        padding:0 15px;
        > div {
            max-width: 1140px;
            margin:0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;                       
        }
    }  
    .info {
        display: flex;
        align-items: center;
        gap:30px;   
        img {
            height:20px;
            @media (min-width:315px) {    
                height:44px;
            }
            vertical-align: middle;
        }         
        .logo {            
            margin-right:12px;
        }
    } 
    .ui {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap:8px;
        a {
            border: 1px solid #5D5D5D;
            border-radius: 10px;
            text-decoration: none; 
            color: #F5F5F5;
            font-size: 1rem;
            height:50px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            @media (hover: hover) {
                transition: 0.2s all;                
                &:hover {
                    background: #5D5D5D;
                    color:#161616                    
                }
            }
        }
    }
    button {
        background:none;
        border: 1px solid #5D5D5D;
        border-radius: 10px;
        user-select: none;
        width:50px;
        aspect-ratio: 1;
        cursor:pointer;
        svg {
            fill:#F5F5F5;
        }
        @media (hover: hover) {
            transition: 0.2s background;
            svg {
                transition: 0.2s fill;
            }
            &:hover {
                background: #5D5D5D;
                svg {
                    fill:#161616
                }
            }
        }
    }
</style>