import { createStore } from 'vuex'
import router from "@/router";
function getPath(path) {  
  //console.log(path)
  //remove special characters first
  path = path.replaceAll('%', '');
  return decodeURIComponent(path).replaceAll(' / ', '-').replaceAll(' - ', '-').replaceAll(' ', '-').toLowerCase();
}
function getImageUrl(file, signageList, baseUrl, logos) {
  if(file === null) {
    return require('@/assets/images/bg_placeholder.jpg');
  }
  let url = `${baseUrl}files/${file}`;
  if(signageList && signageList.length > 0 && signageList.includes(file)) {
    const imageBase = file.substring(0, file.lastIndexOf('.'));    
    url  = `${baseUrl}0/signage/showSignage.php?logoS=${logos.logoS}&logoH=${logos.logoH}&logoV=${logos.logoV}&imageBase=${imageBase}`
  }
  return url;
}
export default createStore({
  state: {
    previewPage:'', //id of the page to be previewed; setSitemap converts this id into a path; the site then redirects to the correct page after the dynamic routes have been added to the router
    sessionDbId:'', //needed to be passed to tracking; returned from getInfo.php  
    presentationId:'',
    baseUrl:'https://speedwaypartner.com/',
    preview:false,  //whether or not the presentation is in preview mode
    purl:'',  //current purl
    currentPage:undefined,
    notOnNavigationPage:['36', '80', '137', '181'],
    track:'',  //dynamic logo based on track
    intro:'', //vimeo id of the intro video; blank if not added
    showMeetTheTeam:'', //Meet The Team Page Name
    rep:{ //rep info from getInfo.php
      name:'',
      department:'',
      email:'',
      phone:''
    },
    client: { //client info from getInfo.php
      firstName:'',
      lastName:'',
      fullName:'',
      title:'',
      company:'',
      email:'',
      logo:'',
    },
    logos:{},
    siteMap:[],
    overrideTemplate:undefined,
    overrideCopy:undefined,
    devMode:false,
    showBuildMode:false,  //this is toggled by the b key when devMode is set to true
    defaultTemplate: 'UpToThreeImagesLeft.vue',
    templates: {  //templates are assigned by id number in the database; these are then mappeed to meaningful names
      "1" : 'TitlePage.vue',
      "2" : 'TextOverFullscreenImage.vue',
      "3" : 'UpToThreeImagesLeft.vue',
      "4" : 'ImageTopHalf.vue',     
      "5" : 'SmallFixedImage.vue',
      "6" : 'TrackLogoFixedImage.vue',
      "7" : 'TrackLogoTextBox.vue',
      "8" : 'ImageSpotlight.vue',
      "9" : 'ImageSpotlightAlternate.vue',
      "10" : 'FloatingCollapsibleCopy.vue',
      "11" : 'FullWidthCollapsibleCopy.vue',
      "12" : 'InfographicStacked.vue',
      "13" : 'InfographicTiled.vue',
      "14" : 'InfographicRow.vue',
      "15" : 'Events.vue',
      "16" : 'MeetTheTeam.vue',      
      "17" : 'UpToThreeImagesFull.vue',      
    },
    staticSlides : {},  //static slides are assigned to specific page ids; these can be mapped here to meaningful names
  },
  mutations: {
    setSitemap (state, siteInfo) {
      let siteMap = [];
      let routes = [];
      let index  = 0;
      let previewPagePath = '';
      //cms is 5 levels deep but the front end removes the top level of elements and places all 2nd level elements together
      console.log('previewPage', state.previewPage)
      siteInfo.arrTopNav.forEach((section) => {             
        siteInfo.sectionInfo[section].child_section.map((childSection) => {
          const childSectionInfo = siteInfo.sectionInfo[childSection];
          let children = [];
          let childIndex = index;          
          const sections = childSectionInfo.child_section.map((subSection) => {            
            const subSectionInfo = siteInfo.sectionInfo[subSection];
            const pages = subSectionInfo.child_content.map((page) => {
              childIndex++;
              const pageInfo = siteInfo.contentInfo[page]; 
              //console.log(pageInfo)             
              if(state.previewPage !== '' && state.previewPage == page){
                previewPagePath = `${subSectionInfo.name} ${pageInfo.title} ${childIndex}`;
              }
              const path = getPath(`${pageInfo.title}-${childIndex}`);
              children.push({
                path:path,
                name:`${subSectionInfo.name} ${pageInfo.title} ${childIndex}`,
                component: state.staticSlides[page] ?? (() => import('../components/templates/TemplateLoader.vue')),
                meta : {
                  id:page,
                  title:pageInfo.title,
                  template:pageInfo.template === '0' ? state.defaultTemplate : pageInfo.template,
                  copy: pageInfo.copy ?? undefined,
                },
                props:{
                  page: {
                    id:page,
                    template:pageInfo.template === '0' ? state.defaultTemplate : pageInfo.template,
                    title:pageInfo.title,
                    copy: pageInfo.copy ?? undefined,
                    background:getImageUrl(pageInfo.background, siteInfo.signageList, state.baseUrl, state.logos),
                    gallery:pageInfo.photos?.map((photo) => {return {title:photo.title, file:getImageUrl(photo.filename, siteInfo.signageList, state.baseUrl, state.logos)}}),
                    video:pageInfo.videos?.map((video) => {return {embed:video.embed, title:video.title, id:video.vimeoId, thumbnail:`https://${video.thumb}`}}).pop(),
                    videos:pageInfo.videos?.map((video) => {return {embed:video.embed, title:video.title, id:video.vimeoId, thumbnail:`https://${video.thumb}`}}),
                    files:pageInfo.docs?.filter(file => file.type !== 'audio').map(audio => { return {title: audio.title, file:`${state.baseUrl}files/${audio.filename}`}}),
                    audio:pageInfo.docs?.filter(file => file.type === 'audio').map(audio => { return {title: audio.title, file:`${state.baseUrl}files/${audio.filename}`}})
                  }
                },
              });
              return {
                template: pageInfo.template,
                name:`${subSectionInfo.name} ${pageInfo.title} ${childIndex}`,
                title:pageInfo.title,
                background:getImageUrl(pageInfo.background, siteInfo.signageList, state.baseUrl, state.logos),
                video:pageInfo.videos?.reduce((video) => {return video?.id})  //can't remember what this is for
              }
            });            
            return {
              name:decodeURIComponent(subSectionInfo.name),
              title:decodeURIComponent(subSectionInfo.name),
              pages:pages
            }
          });
          const path = getPath(childSectionInfo.name);
          let route = {
            path:`/:purl?/${path}/`,
            name:`${decodeURIComponent(childSectionInfo.name)} ${index}`,            
            component: () => import('@/views/SubNavigation'),
            props : {
              title:decodeURIComponent(childSectionInfo.name),
              index:index,
              sections:sections
            },
            children:children
          } 
          let video = sections[0].pages[0].video?.vimeoId;
          let videoUrl = sections[0].pages[0].video?.embed;
          routes.push(route);
          //this is special for smi
          let showInSitemap = true;
          for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
            const element = sections[sectionIndex];
            for (let pageIndex = 0; pageIndex < element.pages.length; pageIndex++) {
              const page = element.pages[pageIndex];
              if(page.template === '16') {
                showInSitemap = false;
                break;
              }
            }
            if(!showInSitemap) {
              break;
            }            
          }         
          //remove for other epitch sites
          if(showInSitemap) {
            siteMap.push({
              id:childSectionInfo.id,
              name:`${decodeURIComponent(childSectionInfo.name)} ${index}`,
              title:decodeURIComponent(childSectionInfo.name),
              video:video,
              videoUrl:videoUrl,
              sections:sections
            });       
            index++;   
          }
        }); //this extra loop through child_section isn't needed if you only have 4 levels
        //console.log(state.previewPage, previewPagePath, siteInfo.contentInfo)
        if(state.previewPage !== '' && previewPagePath === '') {  //page is not on but still need to preview it
          const pageInfo = siteInfo.contentInfo[state.previewPage];
          console.log('show disabled page in preview', pageInfo)
        }
      });
      //remove meet the team route
      let filteredRoutes = routes.filter((parentRoute) => { 
        let filtered = parentRoute.children.filter((route) => {           
          let pageIsMeetTheTeam = route.props.page.template === '16';
          if(pageIsMeetTheTeam) {
            state.showMeetTheTeam = route.name;
          }
          return !pageIsMeetTheTeam;
        });
        return filtered.length > 0});
      //first do a double for loop starting at 0; set all the next pages going up;
      for (let index = 0; index < filteredRoutes.length; index++) {
        const mainRoute = filteredRoutes[index];
        for (let childIndex = 0; childIndex < mainRoute.children.length; childIndex++) {
          const child = mainRoute.children[childIndex];
          let next = mainRoute.children[childIndex + 1]?.name ?? filteredRoutes[index + 1]?.children[0]?.name ?? undefined;
          child.props.page.next = next;
        }        
      }
      //now do a double for loop starting at length; set all the previous pages going down;
      for (let index = filteredRoutes.length - 1; index >= 0; index--) {
        let mainRoute = filteredRoutes[index];
        for (let childIndex = mainRoute.children.length - 1; childIndex >= 0; childIndex--) {
          let child = mainRoute.children[childIndex];
          let previous = mainRoute.children[childIndex - 1]?.name ?? filteredRoutes[index - 1]?.children[filteredRoutes[index - 1]?.children.length - 1]?.name ?? undefined;
          child.props.page.previous = previous;
        }       
      }
      routes.forEach(route => {
        router.addRoute(route);
      });
      state.siteMap = siteMap;
      let purl = state.purl;
      if(state.preview) {
        purl = `preview.${state.purl}`
      }
      if(state.previewPage !== '') {
        router.push({name:previewPagePath, params:{purl:purl}});
      } else {
        router.push({path:router.currentRoute.value.path, params:{purl:purl}});
      }
    },
    setIntro(state, newValue) {
      state.intro = newValue ? newValue : '';
    },
    setTrackLogo(state, siteInfo){      
      const map = {
        'Atlanta Motor Speedway':'Speedway_logos_Atlanta',
        'Bristol Motor Speedway':'Speedway_logos_Bristol',
        'Charlotte Motor Speedway':'Speedway_logos_Charlotte',
        'Kentucky Speedway':'charlotte',
        'Las Vegas Motor Speedway':'Speedway_logos_Las Vegas',
        'New Hampshire Motor Speedway':'Speedway_logos_New Hampshire',
        'Sonoma Raceway':'Speedway_logos_Sonoma',
        'Texas Motor Speedway':'Speedway_logos_Texas',
        'Speedway Motorsports':'Speedway_logos_Speedway',
        'Performance Racing Network':'charlotte',
        'North Wilkesboro Speedway':'nws',
        'US Legends':'charlotte',
      } 
      //Speedway_logos_Circuits of America
      if(siteInfo?.arrTopNav && siteInfo?.sectionInfo) {
        const firstTop = siteInfo?.arrTopNav[0] ?? '';
        state.track = map[siteInfo?.sectionInfo[firstTop]?.name] ?? '';
      }
      try {
        const track = require(`@/assets/logos/${state.track}.svg`)   
      } catch (error) {
          state.track = '';
      }            
    },
    setPreviewPage(state, newValue){
      state.previewPage = newValue
    },
    setPresentationId(state, newValue) {
      state.presentationId = newValue;
    },
    setSessionDBid (state, newValue) {
      state.sessionDbId = newValue
    },
    setPreview (state, newValue) {
      state.preview = newValue
    },
    setPurl (state, newValue) {
      state.purl = newValue
    },
    updateSitemap (state, newValue) {
      state.siteMap = newValue
    },    
    setRep (state, newValue) {
      state.rep = {
        name: newValue.rep ?? '',
        department: newValue.repDepartment ?? '',
        email: newValue.repEmail ?? '',
        phone: newValue.repPhone ?? '',
      }
    },
    setClient (state, newValue) {
      //console.log(newValue)
      state.client = {
        firstName: newValue.firstname ?? '',
        lastName: newValue.lastname ?? '',
        fullName: newValue.client ?? '',
        title: newValue.title ?? '',
        company: newValue.company ?? '',
      };
      const serverLogos = { //added this because I wasn't positive if the logo could be returned as empty from the server instead of undefined
        logo1 : newValue.logo1 === '' ? undefined : newValue.logo1,
        logo2 : newValue.logo2 === '' ? undefined : newValue.logo2,
        logo3 : newValue.logo3 === '' ? undefined : newValue.logo3,
      }
      
      state.logos = {
        logoH : serverLogos.logo1 ?? serverLogos.logo2 ?? serverLogos.logo3 ?? '',
        logoV : serverLogos.logo2 ?? serverLogos.logo1 ?? serverLogos.logo3 ?? '',
        logoS : serverLogos.logo3 ?? serverLogos.logo1 ?? serverLogos.logo2 ?? '',
      }
      
      let homeHogoFile = serverLogos.logo1 ?? serverLogos.logo2 ?? serverLogos.logo3 ?? '';
      try {
        state.client.logo = `${state.baseUrl}logos/${homeHogoFile}`;        
      } catch (error) {
        //console.log('no logo')          
      }    
    },    
  },
  actions: {},
  modules: {}
})