import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
console.log('in main')
const app = createApp(App)
app.use(store)
app.use(router)
router.isReady().then(() => {    
    console.log('purl', router.currentRoute.value)
    app.mount('#app')
})

