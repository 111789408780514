<template>
  <div class="holder" @mouseenter="play" @mouseout="pause">
    <div ref="root">
      <div :id="`p${random}`" class='inner' />
    </div>    
  </div>
</template>

<script>
import Player from "@vimeo/player";
export default {
  name: "Video",
  mounted() {
    this.random = parseInt(Math.random() * 100);
    this.$nextTick(() => {
    const player = new Player(`p${this.random}`, {
      id: this.video,
      background:true,
      responsive: true,      
      muted:true,
      autopause:false,
      byline:false
    });
    });
  },
  props: {         
    video:String,
  },
  methods : {
    play() {
      const iframe = this.$refs.root.querySelector("iframe");
      const player = new Player(iframe);
      //player.play();
    },
    pause() {
      const iframe = this.$refs.root.querySelector("iframe");
      const player = new Player(iframe);
      //player.pause();
    }
  },
  data() {
    return {
      random:'',
    };
  },
};
</script>
<style lang="scss" scoped>
.holder {
  position: relative;
  pointer-events: all;
}
.video {
  font-size: 0;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;  
}
.inner {
  display:grid;
    place-items: center;
     :deep() {
      div {
      height:100%;
      top:0 !important;
      left:0 !important;
      padding:0 !important;
      width:1920px;
      height:1080px;
      }
      iframe {
        pointer-events: none;
      }
    }
}
div {
    height:100%;    
   
  }
</style>