<template>
  <div class="intro-video">  
      <vueVimeoPlayer v-if="intro"      
            frameborder="0"
            class='video'
            :options="{
                responsive:true,
                autoplay:true,
                controls:false,
            }"   
            :style="{
                maxWidth:'100vw',
                maxHeight:'100vh'
            }"
            ref="player"
            :video-url='intro'
        />  
    <transition name="slide" appear>
      <div class='bottom'>
        <div class="logos">
          <img :src="require(`@/assets/logos/logo.svg`)" alt="" class='logo'>
          <img v-if="$store.state.track !== ''" :src="require(`@/assets/logos/${$store.state.track}.svg`)" alt="">
        </div>
        <router-link :to="{name:next, params:$route.params}"><span>Skip Video</span></router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
export default {
  name: 'Intro Video',
  components: {
      vueVimeoPlayer
  },
  mounted() {
    this.$store.state.currentPage = {
      'next': 'Main Navigation'
    }    
    this.next = 'Main Navigation';
  },    
  data: () => ({
    next: 'Home',    
  }),  
  computed : {
    intro() {
      return this.$store.state.intro === '' ? '' : this.$store.state.intro;
    },    
  }
}
</script>
<style lang="scss" scoped>
    .video {
        height:100vh;
        width:100vw;    
        :deep() div {
            padding:0 !important;
            width:100%;
            height:100%;
        }   
    }
  a {
    font-family: 'Helvetica Neue LT Std';
    font-weight: bold;
    border-radius: 10px;
    background: linear-gradient(270deg, #DADADA 0%, #FFFFFF 100%);    
    color: #181818;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    padding:16px 30px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    span {
      position: relative;
    }
    &:before {      
      content:"";      
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      box-sizing: border-box;
      border:0px solid #F5F5F5;    
      border-radius: 10px;
    }
    @media (hover: hover) {
      &:before {
        transition: 0.2s all;
      }
      &:hover {
        &:before {
          border:5px solid #F5F5F5;
        }
      }
    }
  }
  .intro-video {      
    position: relative;    
    .bottom {
        position: absolute;
        bottom:0;
        left:0;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;      
        z-index: 1;        
        padding:0 55px;
        padding-bottom:80px;
        box-sizing: border-box;
        &:after {
            position: absolute;
            bottom:55px;
            left:0;
            width:calc(100% - 110px);
            height:1px;
            content: "";
            background:#223D77;
            margin:0 55px;
        }
    }
  }  
  .logos {
    box-sizing: border-box;
    display:flex;
    align-items: center;
    justify-content: center;
    .logo {
      margin-right:12px;
    }
    img {
        height:55px;
    }
  }
  .divider {
    width:1px;
    height:45px;
    margin:0 22px;
    display:inline-block;
    background-color: #F5F5F5;
  }
</style>
